import React from "react";

import { Box, Container, Button, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function HomePage() {
  return (
    <div>
      <Container
        sx={{
          minHeight: "100vh",
          backgroundColor: "white",
          color: "primary.light",
        }}
      >
        <Typography mt={8} mb={4} variant="h4">
          🍫 🌷 🎇
        </Typography>
        <Typography variant="h1">Chocolate Flower Fireworks</Typography>
      </Container>
    </div>
  );
}

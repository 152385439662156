import "./App.css";
import { Outlet } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { pink, amber } from '@mui/material/colors';


function App() {


  const theme = createTheme({
    status: {
      // danger: orange[500],
    },
    components: {
      // Name of the component
      MuiButtonBase: {
        defaultProps: {
          // The props to change the default for.
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
      MuiButton: {
        defaultProps: {
          // The props to change the default for.
          variant: "outlined",
        },
      },
    },
    palette: {
      primary: pink,
      secondary: amber,
    },
    typography: {
      h1: {
        fontFamily: "park-lane",
        fontWeight: 700,
        fontSize: 72
      },
      h2: {
        fontFamily: "park-lane",
        fontSize: 48
      },
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Outlet />
    </ThemeProvider>
  );
}

export default App;

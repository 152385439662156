import { useState, useEffect, React } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { Box, Container, Grow, Paper, Typography } from "@mui/material";

const note = (
  <Paper sx={{ p: 4 }}>
    <Typography sx={{ fontFamily: "park-lane" }}>
      Dear Laura,
      <br />
      <br></br>
      These past couple months have been lovely. I felt an immediate connection
      to you from day one and it seems like it just keeps getting stronger.
      You're a wonderful person, and I look forward to seeing where things go
      from here. I'd love to formalize things a bit more between us with you
      whenever you're ready.
      <br></br>
      <br></br>✅&nbsp;Dinner with your parents<br></br>✅ &nbsp;Chocolate
      flower fireworks<br></br>
      🔲 &nbsp;Define the relationship<br></br>
      <br></br>
      Yours truly,
      <br></br>
    </Typography>
    <Typography sx={{ mt: 2, fontSize: 24, fontFamily: "park-lane" }}>John</Typography>
  </Paper>
);

export default function DefineTheRelationshipPage() {
  const particlesInit = async (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const particleOptions = {
    fpsLimit: 120,
    particles: {
      number: {
        value: 0,
      },
      color: {
        value: ["#00FFFC", "#FC00FF", "#fffc00"],
      },
      shape: {
        type: "character",
        options: {
          character: {
            fill: true,
            // font: "Verdana",
            // style: "",
            weight: 400,
            particles: {
              size: {
                value: 16,
              },
            },
            value: ["🍫", "💐", "🌻", "🤎", "🟫", "🟤", "🌹", "🌷", "🌼"],
          },
        },
      },
      opacity: {
        value: 1,
        animation: {
          enable: true,
          minimumValue: 0,
          speed: 4,
          startValue: "max",
          destroy: "min",
        },
      },
      size: {
        value: 8,
        random: {
          enable: true,
          minimumValue: 4,
        },
      },
      links: {
        enable: false,
      },
      life: {
        duration: {
          sync: true,
          value: 10,
        },
        count: 1,
      },
      move: {
        enable: true,
        gravity: {
          enable: true,
          acceleration: 10,
        },
        speed: {
          min: 20,
          max: 30,
        },
        decay: 0.1,
        direction: "none",
        straight: false,
        outModes: {
          default: "destroy",
          top: "none",
        },
      },
      rotate: {
        value: {
          min: 0,
          max: 360,
        },
        direction: "random",
        move: true,
        animation: {
          enable: true,
          speed: 60,
        },
      },
      //   tilt: {
      //     direction: "random",
      //     enable: true,
      //     move: true,
      //     value: {
      //       min: 0,
      //       max: 360,
      //     },
      //     animation: {
      //       enable: true,
      //       speed: 60,
      //     },
      //   },
      //   roll: {
      //     darken: {
      //       enable: true,
      //       value: 25,
      //     },
      //     enable: true,
      //     speed: {
      //       min: 15,
      //       max: 25,
      //     },
      //   },
      wobble: {
        distance: 30,
        enable: true,
        move: true,
        speed: {
          min: -15,
          max: 15,
        },
      },
    },
    emitters: {
      life: {
        count: 0,
        duration: 0.1,
        delay: 0.3,
      },
      rate: {
        delay: 0.4,
        quantity: 100,
      },
      size: {
        width: 0,
        height: 0,
      },
    },
    detectRetina: true,
  };

  const [showNote, setShowNote] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowNote(true), 6000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particleOptions}
      />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          backgroundColor: "#282c34",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container maxWidth="sm">
          {/* Conditionally applies the timeout prop to change the entry speed. */}
          <Grow
            in={showNote}
            // style={{ transformOrigin: "0 0 0" }}
            {...(showNote ? { timeout: 2000 } : {})}
          >
            {note}
          </Grow>
        </Container>
      </Box>
    </>
  );
}
